@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@900&display=swap");


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@keyframes bottom {
	0% {
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
	}
	100% {
		clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
	}
}
@-webkit-keyframes bottom {
	0% {
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
	}
	100% {
		clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
	}
}
@keyframes top {
	0% {
		clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
	}
	100% {
		clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
	}
}
@-webkit-keyframes top {
	0% {
		clip-path: polygon(100% 0, 0 0, 0 0, 100% 0);
	}
	100% {
		clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
	}
}
@keyframes orb {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}
@-webkit-keyframes orb {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}